
import React, { useEffect, useMemo, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { Partnership, PARTNERSHIP_CATEGORY } from 'constants/registration';
import PaymentInformationDriver from 'components/atoms/payment-information-driver';
import PaymentInformationDana from 'components/atoms/payment-information-dana';
import { ticketPrice } from 'constants/transaction';
import { getRegistrationByBillNo } from 'datas/registration';
import * as Constant from 'constants/registration';

type PreviewPartnershipProps = {
    registrationNumber: string;
    onSuccessUploadPaymentConfirmation?: () => void;
}

export const PreviewPartnership = (props: PreviewPartnershipProps) => {
    const [dataRegistration, setDataRegistration] = useState<Partnership>();

    const columns = useMemo(() => {
        let cols: { key: keyof Partnership, label: string }[] = [
            { key: 'name', label: 'Nama Lengkap' },
            { key: 'phone', label: 'No Handphone Jemaat' },
            { key: 'partnership_category', label: 'Partnership yang diberikan' },
            { key: 'event_location', label: 'Gereja Lokal' }
        ];

        if (dataRegistration?.partnership_category === Constant.PARTNERSHIP_CATEGORY.DRIVER) {
            cols = cols.concat([
                { key: 'domicile', label: 'Alamat Domisili' },
                { key: 'number_plate', label: 'Plat Nomor' },
            ]);
        } else if (dataRegistration?.partnership_category === Constant.PARTNERSHIP_CATEGORY.DANA) {
            cols.push({ key: 'sponsored_children_count', label: 'Jumlah Anak yang Disponsori' })
        }
        
        return cols;
    }, [dataRegistration?.partnership_category]);

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(props.registrationNumber)
            .then(() => {
                alert('Berhasil menyalin No Registrasi');
            });
    }

    const onLoadDataRegistration = async () => {
        try {
            if (!props.registrationNumber) throw 'Registration number no doesnt exist';

            const data = await getRegistrationByBillNo({
                regNo: props.registrationNumber,
                singleSnapshot: true
            });
            if (data) {
                setDataRegistration(data);
            }

            throw 'Data not found';
        } catch (err) {
            console.error(err);
        }
    }

    const onSuccessUploadPaymentConfirmation = () => {
        if (props.onSuccessUploadPaymentConfirmation) {
            props.onSuccessUploadPaymentConfirmation();
        }
        onLoadDataRegistration();
    }

    useEffect(() => {
        onLoadDataRegistration();
    }, [])

    return dataRegistration ? (
        <div className='preview preview-group'>
            <div className='preview_item'>
                <span className='preview_item_value'>
                    TERIMA KASIH ATAS PENDAFTARAN PARTNERSHIP ANDA DI BIBLE STUDY 2024
                </span>
            </div>

            <Alert>
                <small className='text-muted'>No Registrasi Anda:</small>
                <span className='fw-bold'><u>{props.registrationNumber}</u></span>
                <Button size='sm' onClick={handleCopyToClipboard}>
                    <small>SALIN</small>
                </Button>
            </Alert>

            <hr />

            <div className='preview_item'>
                <span className='preview_item_value'>
                    Data Pendaftaran Anda:
                </span>
            </div>

            {columns.map(({ key, label }) => (
                <div className='preview_item' key={`preview_individual_parent_${key}`}>
                    <small className='text-muted'>{label}</small>
                    <span className='preview_item_value'>{dataRegistration[key] as string || '-'}</span>
                </div>
            ))}

            <hr />

            {dataRegistration.partnership_category === PARTNERSHIP_CATEGORY.DANA
                ? <PaymentInformationDana
                    id={dataRegistration.id}
                    registrationNumber={props.registrationNumber}
                    nominal={dataRegistration.sponsored_children_count * ticketPrice}
                    event_location={dataRegistration.event_location ?? ''}
                    payment_confirmation={dataRegistration.payment_confirmation}
                    className='preview_info'
                    onSuccessUploadPaymentConfirmation={onSuccessUploadPaymentConfirmation}
                />
                : <PaymentInformationDriver className='preview_info' />
            }
        </div>
    ) : null
}