import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Spinner from 'react-bootstrap/Spinner'
import Swal from 'sweetalert2';
import FormCheck from 'components/atoms/form-check';
import FormGroup from 'components/organisms/forms/form-group';
import FormIndividual from 'components/organisms/forms/form-individual';
import FormPartnership from 'components/organisms/forms/form-partnership';
import ArrowRight from 'components/atoms/icons/arrow-right';
import ArrowLeft from 'components/atoms/icons/arrow-left';
import {
    postRegistrationGroup,
    postRegistrationIndividual,
    postRegistrationPartnership
} from 'datas/registration';
import {
    getTransactionByPersonId,
    postGroupRegistrationTransactionNotification,
    postPartnershipDriverTransactionNotification,
    postRegistrationTransactionNotification,
    postTransaction,
    TransactionItem
} from 'datas/transaction';
import { useRegistration } from 'stores/registration';
import { useAuth } from 'stores/auth';
import * as Constant from 'constants/registration';
import { ticketPrice } from 'constants/transaction';
import PreviewIndividual from 'components/organisms/previews/preview-individual';
import PreviewGroup from 'components/organisms/previews/preview-group';
import PreviewPartnership from 'components/organisms/previews/preview-partnership';

export const Registration = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const profile = useAuth().profile;
    const { payloadIndividual, payloadGroup, payloadPartnership, payloadChildren, clearAllPayload } = useRegistration();
    
    const [step, setStep] = useState<Constant.STEP>(Constant.STEP.SEGMENTATION);
    const [validated, setValidated] = useState(false);
    const [billNo, setBillNo] = useState('');
    const [registrationNumber, setRegistrationNumber] = useState('');
    const [formType, setFormType]  = useState<Constant.FORM_TYPE>();
    const [registrationType, setRegistrationType] = useState<Constant.REGISTRATION_TYPE>();
    const [isLoading, setIsLoading] = useState(false);

    const postPartnershipRegistration = async () => {
        const { registration_number } = await postRegistrationPartnership(
            payloadPartnership as Constant.PayloadPartnership, 
            profile!.person_id
        );
        const isDriverPartnership = payloadPartnership?.partnership_category === Constant.PARTNERSHIP_CATEGORY.DRIVER;
    
        if (isDriverPartnership) {
            postPartnershipDriverTransactionNotification({ registrationNumber: registration_number });
        }
    
        setRegistrationNumber(registration_number);
    };
    
    const postIndividualRegistration = async () => {
        const transactionItems = payloadChildren.map(({ name }) => {
            const ticketId = Constant.child_pick_up.find(({ value }) => value === payloadIndividual?.pick_up_type);
            return {
                item_id: ticketId?.ticketId ?? Constant.child_pick_up[0].ticketId,
                qty: 1,
                notes: name,
            }
        });
        
        let { bill_no, redirect_url } = await getTransactionDetails(transactionItems);

        setBillNo(bill_no);
    
        const payload = {
            ...payloadIndividual as Constant.PayloadIndividual,
            children: payloadChildren.map(child => ({
                ...child,
                pick_up_type: payloadIndividual?.pick_up_type ?? ''
            }))
        };
        await postRegistrationIndividual(payload, profile!.person_id, bill_no, redirect_url);
    
        const nominal = payloadChildren.length * ticketPrice;
        postRegistrationTransactionNotification({
            registrationNumber: bill_no,
            redirectUrl: redirect_url,
            nominal
        });

        await getTransactionByPersonId(profile?.person_id ?? '');
    };

    const postGroupRegistration = async () => {
        const payload = {
            ...payloadGroup as Constant.PayloadGroup,
            student_count: payloadChildren.length,
            students: payloadChildren.map(({ name, grade, gender }) => ({ name, grade, gender }))
        } as Constant.PayloadGroup;
        const { registration_number } = await postRegistrationGroup(payload as Constant.PayloadGroup, profile!.person_id);
        postGroupRegistrationTransactionNotification({
            ...payload,
            registrationNumber: registration_number,
        });
        setRegistrationNumber(registration_number);
    };
    
    const getTransactionDetails = async (transaction: TransactionItem[]) => {
        let bill_no = billNo, redirect_url = '';
    
        if (!bill_no) {
            const response = await postTransaction(profile!.person_id, transaction);
            bill_no = response.bill_no;
            redirect_url = response.redirect_url;
        }
    
        return { bill_no, redirect_url };
    };

    const onPrevStep = () => {
        setStep(prev => (parseInt(prev) - 1).toString() as Constant.STEP);
    }

    const onNextStep = () => {
        setStep(prev => (parseInt(prev) + 1).toString() as Constant.STEP);
    }

    const onSuccessUploadPaymentConfirmation = () => {
        navigate('/transaction');
    }

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
    
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            Swal.fire(
                'Data Belum Benar',
                '* Menunjukkan pertanyaan wajib diisi. Pastikan semua data terisi dengan benar.',
                'error'
            );
            return;
        }
    
        try {
            setIsLoading(true);
    
            if (step === Constant.STEP.FORM || step === Constant.STEP.PREVIEW) {
                if (formType === Constant.FORM_TYPE.PARTNERSHIP) {
                    await postPartnershipRegistration();
                } else if (registrationType === Constant.REGISTRATION_TYPE.INDIVIDUAL) {
                    await postIndividualRegistration();
                } else if (registrationType === Constant.REGISTRATION_TYPE.GROUP) {
                    await postGroupRegistration();
                }
            }
    
            onNextStep();
        } catch (err: any) {
            const message = typeof err === 'string' 
                ? err
                : err?.response?.data?.message ?? 'Terjadi kesalahan pada proses pendaftaran';
    
            Swal.fire(
                'Pendaftaran Belum Berhasil',
                message,
                'error'
            );
        } finally {
            setValidated(false);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        const bill_no = location.state?.billNo;
        if (bill_no) setBillNo(bill_no);
        return () => {
            setBillNo('');
            clearAllPayload();
        }
    }, [])

    useEffect(() => {
        if (window) {
            window.scrollTo(0, 0);
        }
    }, [step])

    const stepProgressPct = useMemo(() => 
        parseInt(step) / (formType === Constant.FORM_TYPE.PARTNERSHIP ? 2 : 3) * 100
    , [step, formType])
    
    return (
        <section className='registration'>
            <ProgressBar now={stepProgressPct} className='registration_progress' />
            
            <Form noValidate validated={validated} onSubmit={onSubmit}>

                {/* segmentation */}
                {step === Constant.STEP.SEGMENTATION && (
                    <Form.Group>
                        <div className='mb-4'>
                            <h2>Pendaftaran Bible Study Eaglekidz</h2>
                            <span className='text-danger'>* Menunjukkan pertanyaan wajib diisi</span>
                        </div>
                        <Card className='mb-2'>
                            <Card.Body>
                                <FormCheck
                                    required
                                    label='Jenis Pendaftaran'
                                    value={formType ?? ''}
                                    options={Constant.form_type}
                                    onChange={(value) => setFormType(value as Constant.FORM_TYPE)}
                                />
                                <span className="separator" />
                                <Form.Control.Feedback type="invalid">
                                    Pertanyaan wajib diisi
                                </Form.Control.Feedback>
                            </Card.Body>
                        </Card>
                        
                        {formType === Constant.FORM_TYPE.BIBLE_STUDY && (
                            <Card>
                                <Card.Body>
                                    <FormCheck
                                        required={formType === Constant.FORM_TYPE.BIBLE_STUDY}
                                        label='Kategori Pendaftaran'
                                        value={registrationType ?? ''}
                                        options={Constant.registration_type}
                                        onChange={(value) => setRegistrationType(value as Constant.REGISTRATION_TYPE)}
                                    />
                                    <span className="separator" />
                                    <Form.Control.Feedback type="invalid">
                                        Pertanyaan wajib diisi
                                    </Form.Control.Feedback>
                                </Card.Body>
                            </Card>
                        )}
                    </Form.Group>
                )}

                {/* form */}
                {step === Constant.STEP.FORM
                    ? formType === Constant.FORM_TYPE.PARTNERSHIP
                        ? <FormPartnership />
                        : registrationType === Constant.REGISTRATION_TYPE.INDIVIDUAL
                            ? <FormIndividual />
                            : <FormGroup />
                    : null
                }

                {/* preview */}
                {step === Constant.STEP.PREVIEW
                    ? formType === Constant.FORM_TYPE.PARTNERSHIP
                        ? <PreviewPartnership
                            registrationNumber={registrationNumber}
                            onSuccessUploadPaymentConfirmation={onSuccessUploadPaymentConfirmation} 
                        />
                        : registrationType === Constant.REGISTRATION_TYPE.INDIVIDUAL
                            ? <PreviewIndividual billNo={billNo} />
                            : <PreviewGroup registrationNumber={registrationNumber} />
                    : null
                }
                
                <div className='registration_footer'>
                    {step === Constant.STEP.FORM &&
                        <Button
                            className='d-flex align-items-center justify-content-center gap-3'
                            onClick={onPrevStep}
                            disabled={isLoading}
                        >
                            <ArrowLeft />
                            Kembali
                        </Button>
                    }

                    {step === Constant.STEP.SEGMENTATION || step === Constant.STEP.FORM ?
                        <Button
                            type='submit'
                            className='d-flex align-items-center justify-content-center gap-3'
                            disabled={isLoading}
                        >
                            {step === Constant.STEP.FORM ? 'Kirim' : 'Selanjutnya'}
                            {isLoading ?
                                <Spinner size='sm' variant='light' />
                            :
                                <ArrowRight />
                            }
                        </Button>
                    : null}
                </div>
            </Form>
        </section>
    )
}