import React, { ChangeEvent, useMemo, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import { payment_branch } from 'constants/registration';
import { formatAmount } from 'utils/format';
import Swal from 'sweetalert2';
import { postPartnershipPaymentConfirmation } from 'datas/registration';
import { postPartnershipDanaTransactionNotification } from 'datas/transaction';

type PaymentInformationDanaProps = {
    id: string;
    registrationNumber: string;
    nominal: number;
    event_location: string;
    payment_confirmation?: string | null;
    className?: string;
    onSuccessUploadPaymentConfirmation?: () => void;
}


export const PaymentInformationDana = (props: PaymentInformationDanaProps) => {
    const inputFile = useRef<HTMLInputElement | null>(null);

    const handleReset = () => {
        if (inputFile.current) {
            inputFile.current.value = "";
            inputFile.current.type = "text";
            inputFile.current.type = "file";
        }
    };

    const onSelectPaymentConfirmation = () => {
        inputFile.current?.click();
    }

    const onUploadPaymentConfirmation = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0] ? e.target.files[0] : '';
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            Swal.fire({
                html: `
                    <img src=${fileUrl} style='width: 100%; padding: 0.6rem 0;' />
                `,
                confirmButtonText: 'Unggah File',
                confirmButtonColor: '#000',
                showLoaderOnConfirm: true,
                showCancelButton: true,
                cancelButtonText: 'Batal',
                preConfirm: () => {
                    const data = new FormData();
                    data.append('payment-confirmation', file);
                    postPartnershipPaymentConfirmation(props.id, data)
                        .then(() => {
                            postPartnershipDanaTransactionNotification({
                                registrationNumber: props.registrationNumber,
                                eventLocation: props.event_location,
                                nominal: props.nominal
                            });
                            
                            Swal.fire({
                                icon: 'success',
                                title: 'Berhasil Unggah Bukti Pembayaran',
                                text: 'Pembayaran Anda terverifikasi maksimal 1x24 jam hari kerja setelah pembayaran dilakukan, dan akan menerima notifikasi melalui aplikasi GMS Church.'
                            }).then(callback => {
                                if (callback.isConfirmed && props.onSuccessUploadPaymentConfirmation) {
                                    props.onSuccessUploadPaymentConfirmation();
                                }
                            })
                        })
                        .catch(() => {
                            Swal.fire({
                                icon: 'error',
                                title: 'Belum Berhasil Unggah Bukti Pembayaran',
                                text: 'Pastikan bahwa foto dokumen yang Anda unggah memiliki format dan tipe file JPG, JPEG atau PNG.'
                            });
                        })
                }
            }).finally(() => {
                handleReset();
            })
        }
    }

    const paymentBranch = useMemo(() => {
        if (!props.event_location) return null;

        return payment_branch.find(
            ({ value }) => value.toLowerCase?.() === props.event_location.toLowerCase?.());
    }, [props.event_location])

    return (
        <div className={`preview_info ${props.className ?? ''}`}>
            {props.payment_confirmation ? (
                <>
                    <span className='preview_item_value d-block mb-2'>Bukti Pembayaran:</span>
                    <div className='preview_info_image_preview'>
                        <img className='report_children_img' src={`data:image/jpeg;base64,${props.payment_confirmation}`} />
                        <Button href={`data:application/octet-stream;base64,${props.payment_confirmation}`}>Unduh Bukti Pembayaran</Button>
                    </div>
                </>
            ) : (
                <>
                    <span className='mb-3 text-justify'>
                        Silahkan melakukan pembayaran sebesar {formatAmount(props.nominal)} ke rekening:
                    </span>
                    <div className='my-3 text-center fw-bold text-justify'>
                        <span>{paymentBranch?.value}</span>
                        <span>{paymentBranch?.bank}</span>
                        <span>{paymentBranch?.bank_no}</span>
                        <span>{paymentBranch?.desc}</span>
                    </div>
                    <span className='mb-3 text-justify'>
                        Setelah berhasil melakukan pembayaran, silakan unggah bukti pembayaran pada form berikut:
                    </span>

                    <div className='d-flex flex-column align-items-center'>
                        <Button className='mb-3' onClick={onSelectPaymentConfirmation}>
                            Unggah Bukti Pembayaran
                        </Button>
                        <input ref={inputFile} type='file' onChange={onUploadPaymentConfirmation} style={{ display: 'none' }} />
                    </div>
                </>
            )}

            <span className='mb-3 text-justify'>
                Pembayaran Anda terverifikasi maksimal 1x24 jam hari kerja setelah pembayaran dilakukan, dan akan menerima notifikasi melalui aplikasi GMS Church.
                <br />
            </span>

            <span className='mb-3 text-justify'>
                Apabila dalam 1 hari kerja Anda belum menerima email verifikasi, Anda dapat menghubungi kami melalui: 
            </span>

            <span className='mb-2'>
                Whatsapp : Kak Nina (0878-4663-1288) atau Kak Fenisia (0813-1096-3646)
            </span>

            <span className='mb-3'>
                IG : @gmsjakartabarat / @gmstangerang / @gmsbogor / @gmskelapagading / @gmscikarang / @gmsbekasi / @gmsbandung / @gmscirebon / @gmssukabumi
            </span>

            <span>Terimakasih.</span>
            <span>Tuhan memberkati.</span>
        </div>
    )
}