import { TransactionStatus } from "./transaction";

type Partial<T> = {
    [P in keyof T]?: T[P];
};

export enum STEP {
    SEGMENTATION = '0',
    FORM = '1',
    PREVIEW = '2'
}

export enum FORM_TYPE {
    PARTNERSHIP = '0',
    BIBLE_STUDY = '1'
}

export enum REGISTRATION_TYPE {
    INDIVIDUAL = '0',
    GROUP = '1'
}

export enum PARTNERSHIP_CATEGORY {
    DRIVER = 'DRIVER',
    DANA = 'DANA'
}

export enum PICKUP_TYPE {
    PICKEDUP = '0',
    UNPICKED = '1'
}

export type RegistrationHistory<T> = T & { category: RegistrationCategory };

export type RegistrationCategory = 'parent' | 'school' | 'partnership';

export type DataRegistration = RegistrationHistory<Individual> 
    & RegistrationHistory<Group> 
    & RegistrationHistory<Partnership>;

export type Individual = {
    id: string;
    parent_name: string;
    parent_phone: string;
    parent_email: string;
    parent_address: string;
    parent_domicile: string;
    event_location: string;
    bill_no: string;
    redirect_url: string | null;
    person_id: string;
    created_at: string;
    updated_at: string | null;
    is_ticket_generated: number;
    ticket_generated_at: string | null;
    registration_number: string;
    children: Child[];
}

export type Group = {
    id: string;
    registration_number: string;
    school_name: string;
    school_email: string;
    teacher_name: string;
    teacher_phone: string;
    school_domicile: string;
    event_location: string;
    student_count: number;
    person_id: string;
    bill_no: string;
    redirect_url: string;
    created_at: string;
    updated_at: string | null;
    teacher_count: number;
    student: Child[];
}

export type Partnership = {
    id: string;
    registration_number: string;
    name: string;
    nij: string;
    phone: string;
    partnership_category: string;
    number_plate: string | null;
    car_type: string | null;
    car_color: string | null;
    car_load: string | null;
    sponsored_children_count: number;
    created_at: string;
    cg_number: string | null;
    person_id: string;
    event_location: string | null;
    payment_confirmation: string | null;
    domicile: string | null;
}

export class RegistrationHistoryList {
    parent = [] as Individual[];
    school = [] as Group[];
    partnership = [] as Partnership[];
}

export class PayloadPartnership {
    name = '';
    nij = '';
    cg_number?: string;
    phone = '';
    partnership_category?: PARTNERSHIP_CATEGORY;
    number_plate = '';
    car_type = '';
    car_color = '';
    car_load = '';
    sponsored_children_count = '';
    event_location = '';
    payment_confirmation?: string;
    domicile = '';
}

export class PayloadIndividual {
    parent_name = '';
    parent_phone = '';
    parent_email = '';
    parent_address = '';
    parent_domicile = '';
    parent_domicile_other?: string;
    event_location = '';
    pick_up_type = '';
    children: Child[] = [new Child()];
}

export class PayloadGroup {
    teacher_name = '';
    teacher_phone = '';
    school_name = '';
    school_email = '';
    school_domicile = '';
    school_domicile_other?: string;
    event_location = '';
    student_count?: number;
    teacher_count?: number;
    students: Child[] = [new Child()];
    student?: Child[] = [new Child()]; // for get
}

export type PayloadTransaction = {
    status: TransactionStatus;
    bill_no: string;
    created_at: string;
} & PayloadIndividual;

export type Transaction = {
    status: TransactionStatus;
    bill_no: string;
    created_at: string;
    registration_number?: string;
}

export class Child {
    id?: string;
    name: string = '';
    grade: string = '';
    gender: string = '';
    phone: string = '';
    is_join_cg?: string;
    cg_number = '';
    pick_up_type: string = '';
    parent_id?: string;
    parent?: Partial<Individual>;
}

export const registration_type = [
    { value: REGISTRATION_TYPE.INDIVIDUAL, label: 'Individu (pribadi atau keluarga)' },
    { value: REGISTRATION_TYPE.GROUP, label: 'Kelompok (sekolah-sekolah)' }
];

export const form_type = [
    { value: FORM_TYPE.BIBLE_STUDY, label: 'Pendaftaran Bible Study' },
    { value: FORM_TYPE.PARTNERSHIP, label: 'Pendaftaran Partnership' }
]

export const domicile_address = [
    { value: "JAKARTA UTARA", label: "JAKARTA UTARA"},
    { value: "JAKARTA TIMUR", label: "JAKARTA TIMUR"},
    { value: "JAKARTA SELATAN", label: "JAKARTA SELATAN"},
    { value: "JAKARTA BARAT", label: "JAKARTA BARAT"},
    { value: "JAKARTA PUSAT", label: "JAKARTA PUSAT"},
    { value: "TANGERANG KOTA", label: "TANGERANG KOTA"},
    { value: "TANGERANG SELATAN", label: "TANGERANG SELATAN"},
    { value: "BOGOR", label: "BOGOR"},
    { value: "DEPOK", label: "DEPOK"},
    { value: "CIKARANG", label: "CIKARANG"},
    { value: "BEKASI", label: "BEKASI"},
    { value: "BANDUNG", label: "BANDUNG"},
    { value: "CIREBON", label: "CIREBON"},
    { value: "SUKABUMI", label: "SUKABUMI"},
    { value: "OTHERS", label: "OTHERS"}
];

export const event_location = [
    { value: "GMS JAKARTA", label: "GMS JAKARTA"},
    { value: "GMS TANGERANG", label: "GMS TANGERANG"},
    { value: "GMS KELAPA GADING", label: "GMS KELAPA GADING"},
    { value: "GMS CIKARANG", label: "GMS CIKARANG"},
    { value: "GMS BEKASI", label: "GMS BEKASI"},
    { value: "GMS BOGOR", label: "GMS BOGOR"},
    { value: "GMS BANDUNG", label: "GMS BANDUNG"},
    { value: "GMS CIREBON", label: "GMS CIREBON"},
    { value: "GMS SUKABUMI", label: "GMS SUKABUMI"}
];

export const payment_branch = [
    {
        value: "GMS JAKARTA",
        label: "GMS Jakarta Barat",
        bank: "BCA",
        bank_no: "088.27.777.05",
        desc: "A.n. Gereja Mawar Sharon 2"
    },
    {
        value: "GMS TANGERANG",
        label: "GMS Tangerang",
        bank: "BCA",
        bank_no: "088.27.77.900",
        desc: "A.n. Gereja Mawar Sharon 2"
    },
    {
        value: "GMS KELAPA GADING",
        label: "GMS Kelapa Gading",
        bank: "BCA",
        bank_no: "088.77.888.91",
        desc: "A.n. Gereja Mawar Sharon 2"
    },
    {
        value: "GMS CIKARANG",
        label: "GMS Cikarang",
        bank: "BCA",
        bank_no: "088.37.36.700",
        desc: "A.n. Gereja Mawar Sharon 2"
    },
    {
        value: "GMS BEKASI",
        label: "GMS Bekasi",
        bank: "BCA",
        bank_no: "088.77.222.11",
        desc: "A.n. Gereja Mawar Sharon 2"
    },
    {
        value: "GMS BOGOR",
        label: "GMS Bogor",
        bank: "BCA",
        bank_no: "088.27.11.119",
        desc: "A.n. Gereja Mawar Sharon 2"
    },
    {
        value: "GMS BANDUNG",
        label: "GMS Bandung",
        bank_no: "088.24.777.79",
        desc: "A.n. Gereja Mawar Sharon 2"
    },
    {
        value: "GMS CIREBON",
        label: "GMS Cirebon",
        bank_no: "088.24.01.900",
        desc: "A.n. Gereja Mawar Sharon 2"
    },
    {
        value: "GMS SUKABUMI",
        label: "GMS Sukabumi",
        bank_no: "088.24.99.900",
        desc: "A.n. Gereja Mawar Sharon 2"
    }
];

export const partnershipCategory = [
    { value: PARTNERSHIP_CATEGORY.DRIVER, label: 'Mobil dan Driver' },
    { value: PARTNERSHIP_CATEGORY.DANA, label: 'Dana' }
]

export const payment_status = [
    { value: TransactionStatus.PAID, label: TransactionStatus.PAID },
    { value: TransactionStatus.WAITING, label: TransactionStatus.WAITING },
    { value: TransactionStatus.EXPIRED, label: TransactionStatus.EXPIRED },
]

export const gender = [
    { value: 'P', label: 'Perempuan'},
    { value: 'L', label: 'Laki-laki'},
];

export const is_join_cg = [
    { value: 'Y', label: 'Sudah'},
    { value: 'N', label: 'Belum'},
];

export const grade = [
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
]

export const child_pick_up = [
    { value: PICKUP_TYPE.PICKEDUP, label: 'Anak akan dijemput orang tua di area Gereja Mawar Sharon', ticketId: 'e2345151871c3da940de58b31201c3fb' },
    { value: PICKUP_TYPE.UNPICKED, label: 'Anak tidak dijemput di area Gereja Mawar Sharon/ diijinkan untuk meninggalkan Gereja Mawar Sharon sendiri', ticketId: 'e2345151871c3da940de58b312020ae1' },
];

export const numberDescriptions = [
    '',
    'pertama',
    'kedua',
    'ketiga',
    'keempat',
    'kelima',
    'keenam',
    'ketujuh',
    'kedelapan',
    'kesembilan',
    'kesepuluh',
    'sebelas',
    'dua belas',
    'tiga belas',
    'empat belas',
    'lima belas',
    'enam belas',
    'tujuh belas',
    'delapan belas',
    'sembilan belas',
    'dua puluh',
];

export const eventPeriod = '2024';